import React from 'react';
import styled, { css } from 'styled-components';

import LandingPageSection from '../LandingPageSection';
import ServiceCard from '../ServiceCard';

import OutsourcingIllustration from '../../assets/images/outsourcing.svg';
import ConsultingIllustration from '../../assets/images/consulting.svg';
import AnalysisIllustration from '../../assets/images/analysis.svg';
import ModellingIllustration from '../../assets/images/modelling.svg';

const services = [
  {
    id: 'outsourcing',
    title: 'Аутсорсинг',
    description: [
      {
        type: `paragraph`,
        content: `Фахівці Auxilium Solutions розробили підходи та запровадили методики, які звілнять вас від рішень, пов’язаних із організацією бухгалтерського та податкового обліку. Співпрацюючи з нами, ви отримаєте бухгалтерську службу “під ключ” з повним комплексом послуг: від складання первинних документів до формування специфічної звітності`,
      },
      {
        type: `list`,
        content: {
          header: `Ми пропонуємо наступний комплекс послуг:`,
          items: [
            `ведення бухгалтерського обліку за стандартами П(С)БО`,
            `ведення бухгалтерського обліку за власними стандартами клієнта`,
            `підготовка фінансової звітності відповідно до П(С)БО`,
            `складання податкових декларацій`,
            `кадрове діловодство`,
          ],
        },
      },
    ],
    alignment: 'left',
    image: OutsourcingIllustration,
  },
  {
    id: 'consulting',
    title: 'Консалтинг',
    description: [
      {
        type: `paragraph`,
        content: `Консалтингові послуги допомагають компанії підвищити інвестиційну привабливість та її вартість на ринку.`,
      },
      {
        type: `list`,
        content: {
          items: [
            `Аналіз корпоративного управління`,
            `Антикорупційні інформаційно-консультаційні послуги`,
            `Розробка регламентів та аналіз бізнес-процесів`,
            `Розробка систем аналізу та зниження витрат`,
            `Управлінський облік`,
          ],
        },
      },
    ],
    alignment: 'right',
    image: ConsultingIllustration,
  },
  {
    id: 'analysis',
    title: 'Фінансовий аналіз та менеджмент',
    description: [
      {
        type: `list`,
        content: {
          items: [
            `Планування бюджету (бюджетування)`,
            `Аналіз виконання бюджету`,
            `Податкове планування`,
            `Фінансово-економічний аналіз`,
            `Ведення платіжного календаря`,
            `Розробка бізнес-плану`,
            `Фінансовий аналі`,
          ],
        },
      },
    ],
    alignment: 'left',
    image: AnalysisIllustration,
  },
  {
    id: 'modelling',
    title: 'Оцінка та фінансове моделювання',
    description: [
      {
        type: `paragraph`,
        content: `Фахівці команди мають понад 10 років досвіду співпраці із найбільшими підприємствами України у сфері оцінки та фінансового моделювання. Ми  приймаємо участь в угодах купівлі-продажу компаній, надаємо підтримку в структуруванні угод з нематеріальними активами і виконуємо оцінку ключових державних активів.`,
      },
      {
        type: `list`,
        content: {
          header: `Наші основні послуги:`,
          items: [
            `Побудова фінансових моделей та прогнозів`,
            `Оцінка бізнесу та активів при угодах M&A`,
            `Оцінка активів для IFRS звітності`,
            `Тестування активів на знецінення`,
            `Оцінка акцій та корпоративних прав`,
            `Оцінка нематеріальних активів, патентів`,
            `Оцінка комерційної нерухомості`,
            `Оцінка для цілей кредитування і реструктуризації`,
            `Оцінка для цілей оподаткування та страхування`,
            `Інвестиційна оцінка`,
            `Оцінка активів та бізнесу для цілей розглядів у міжнародних судах`,
            `Огляд та рецензування звітів про оцінку`,
          ],
        },
      },
    ],
    alignment: 'right',
    image: ModellingIllustration,
  },
];

const ServicesSection = () => (
  <ServicesSectionWrapper id="services">
    <LandingPageSection>
      {services.map((service) => (
        <ServiceCard key={`service-${service.id}`} service={service} />
      ))}
    </LandingPageSection>
  </ServicesSectionWrapper>
);

export default ServicesSection;

const BackgroundCircle = css`
  display: inline-block;
  width: 80vw;
  height: 80vw;
  border-radius: 50%;
  background-color: var(--backgroundElementsColor);
`;

const ServicesSectionWrapper = styled.div`
  margin-bottom: 8rem;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: -50%;
    top: 10%;
    ${BackgroundCircle};
  }

  &::after {
    content: '';
    position: absolute;
    right: -50%;
    bottom: 5%;
    ${BackgroundCircle};
  }
`;

import React from 'react';
import styled from 'styled-components';

import SectionHeader from './SectionHeader';
import Paragraph from './Paragraph';

type PropsType = {
  service: {
    id: string;
    title: string;
    description: Array<{ type: string; content: any }>;
    alignment: string;
    image: string;
  };
};

const Content = ({ type, content }: { type: string; content: any }) => {
  switch (type) {
    case `paragraph`:
      return (
        <DescriptionItem>
          <Paragraph>{content}</Paragraph>
        </DescriptionItem>
      );
    case `list`:
      return (
        <DescriptionItem>
          {content.header?.length > 0 && (
            <ListHeader>{content.header}</ListHeader>
          )}
          <DescriptionList>
            {content.items.map((item: string) => (
              <DescriptionListItem key={item}>
                <Paragraph>{item}</Paragraph>
              </DescriptionListItem>
            ))}
          </DescriptionList>
        </DescriptionItem>
      );
    default:
      return null;
  }
};

const ServiceCard = ({ service }: PropsType) => (
  <ServiceCardWrapper>
    <SectionHeader>{service.title}</SectionHeader>
    <ServiceCardContent>
      {service.alignment === 'left' ? (
        <>
          <LeftColumn>
            {service.description.map(({ type, content }) => (
              <Content type={type} content={content} />
            ))}
          </LeftColumn>
          <RightImageColumn>
            <img src={service.image} />
          </RightImageColumn>
        </>
      ) : (
        <>
          <LeftImageColumn>
            <img src={service.image} />
          </LeftImageColumn>
          <RightColumn>
            {service.description.map(({ type, content }) => (
              <Content type={type} content={content} key={content} />
            ))}
          </RightColumn>
        </>
      )}
    </ServiceCardContent>
  </ServiceCardWrapper>
);

export default ServiceCard;

const DescriptionItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

const ServiceCardWrapper = styled.article`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 10rem;
  }
`;

const ServiceCardContent = styled.main`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 2rem;
`;

const ImageColumn = styled.div`
  display: flex;
`;

const RightImageColumn = styled(ImageColumn)`
  grid-column: 6/11;
  justify-content: flex-end;
`;

const LeftImageColumn = styled(ImageColumn)`
  grid-column: 1/6;
`;

const LeftColumn = styled.div`
  grid-column: 1/6;
`;

const RightColumn = styled.div`
  grid-column: 6/11;
`;

const ListHeader = styled(Paragraph)`
  margin-bottom: 0.5rem;
`;

const DescriptionList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

const DescriptionListItem = styled.li`
  margin-bottom: 0.5rem;
`;

import React from 'react';

import { MainLayout } from '../layouts';

import {
  AboutUsSection,
  HeroBannerSection,
  ReferencesSection,
  ContactSection,
  SEO,
} from '../components';

import ServiesSection from '../components/services-section';

const IndexPage = () => (
  <MainLayout>
    <SEO title="Фінансові послуги в Україні" />
    <HeroBannerSection imageFilename="hero-banner-backgorund-1.png" />
    <AboutUsSection />
    <ServiesSection />
    {/* <ReferencesSection /> */}
    {/* <HeroBannerSection imageFilename="hero-banner-backgorund-2.png" /> */}
    {/* <ContactSection /> */}
  </MainLayout>
);

export default IndexPage;
